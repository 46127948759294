var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar-nav', {
    staticClass: "nav"
  }, [_c('b-nav-item', [_c('dark-Toggler', {
    staticClass: "d-block"
  }), _c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(" Tema")])], 1), _c('b-nav-item', {
    on: {
      "click": function click($event) {
        return _vm.$router.go();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "AlertTriangleIcon"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(" Repair")])], 1), _c('b-nav-item', {
    on: {
      "click": function click($event) {
        return _vm.zoom();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "ZoomInIcon"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(" Zoom IN")])], 1), _c('b-nav-item', {
    on: {
      "click": function click($event) {
        return _vm.unZoom();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": "ZoomOutIcon"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(" Zoom OUT")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }