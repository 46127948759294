const myACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'agen',
    action: 'manage'
  },
  {
    resource: 'agen all',
    action: 'manage'
  },
  {
    resource: '',
    action: 'SAL'
  },
  {
    resource: 'special',
    action: 'manage'
  },
  {
    resource: '',
    action: 'TBBM'
  },
  {
    resource: '',
    action: 'TBBM JETTY 3A'
  },
  {
    resource: '',
    action: 'Hulu Migas'
  },
  {
    resource: 'qrcode',
    action: 'manage'
  }
]
export default [
  {
    header: 'MONITORING',
    acl: myACL
  },
  {
    title: 'Jalur Darat',
    icon: 'TruckIcon',
    tag: 'M.JD',
    tagVariant: 'light-success',
    acl: myACL,
    children: [
      {
        key: 'Land Route1',
        title: 'Form',
        icon: 'MapIcon',
        route: 'monitoring-jalur-darat-form-type-1',
        tag: 'T1',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Route2',
        title: 'Form',
        icon: 'MapIcon',
        route: 'monitoring-jalur-darat-form-type-2',
        tag: 'T2',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'form2',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Route13',
        title: 'Form',
        icon: 'MapIcon',
        route: 'monitoring-jalur-darat-form-type-3',
        tag: 'T3',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'form3',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      }
    ]
  },
  {
    title: 'Jalur Darat',
    icon: 'TruckIcon',
    tag: 'M.JDS',
    tagVariant: 'light-success',
    acl: myACL,
    children: [
      {
        key: 'Land Mon1',
        title: 'T1',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-lrv-mon-1',
        tag: 'Pending Approve',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Mon5',
        title: 'T1',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-lrv-mon-5',
        tag: 'Approved',
        tagVariant: 'success',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Mon2',
        title: 'T1',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-lrv-mon-2',
        tag: 'ClockIN',
        tagVariant: 'secondary',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Mon3',
        title: 'T1',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-lrv-mon-3',
        tag: 'Used',
        tagVariant: 'primary',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      },
      {
        key: 'Land Mon4',
        title: 'T1',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-lrv-mon-4',
        tag: 'Expired',
        tagVariant: 'danger',
        acl: [
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'qrcode',
            action: 'manage'
          }
        ]
      }
    ]
  }
]
