// const myACL = [
//   {
//     resource: 'all',
//     action: 'manage',
//   },
//   {
//     resource: 'user',
//     action: 'manage',
//   },
// ]
export default [
  {
    header: 'HELPER'
    // acl: myACL,
  },
  {
    title: 'Informations',
    icon: 'InfoIcon',
    route: 'sa-guide'
    // acl: myACL,
  },
  {
    title: 'Company Profile SAL',
    icon: 'InfoIcon',
    href: 'https://landing.salpalaran.com/',
    icon: 'SlackIcon'
    // acl: myACL,
  },
  {
    title: 'Akun',
    icon: 'InfoIcon',
    route: 'change-password'
  }
]
