var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customizer d-none d-md-block",
    class: {
      open: _vm.isCustomizerOpen
    }
  }, [_c('b-link', {
    staticClass: "customizer-toggle d-flex align-items-center justify-content-center",
    on: {
      "click": function click($event) {
        _vm.isCustomizerOpen = !_vm.isCustomizerOpen;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "spinner",
    attrs: {
      "icon": "SettingsIcon",
      "size": "15"
    }
  })], 1), _c('div', {
    staticClass: "customizer-section d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XIcon",
      "size": "18"
    },
    on: {
      "click": function click($event) {
        _vm.isCustomizerOpen = !_vm.isCustomizerOpen;
      }
    }
  })], 1), _c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Skin"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "skin-radio-group",
      "name": "skin",
      "options": _vm.skinOptions
    },
    model: {
      value: _vm.skin,
      callback: function callback($$v) {
        _vm.skin = $$v;
      },
      expression: "skin"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Content Width"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "content-width-radio-group",
      "name": "content-width",
      "options": _vm.contentWidthOptions
    },
    model: {
      value: _vm.contentWidth,
      callback: function callback($$v) {
        _vm.contentWidth = $$v;
      },
      expression: "contentWidth"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "RTL",
      "label-cols": "10"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "mr-0 mt-50",
    attrs: {
      "name": "is-rtl",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.isRTL,
      callback: function callback($$v) {
        _vm.isRTL = $$v;
      },
      expression: "isRTL"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Router Transition",
      "label-cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "clearable": false,
      "label": "title",
      "options": _vm.routerTransitionOptions,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.routerTransition,
      callback: function callback($$v) {
        _vm.routerTransition = $$v;
      },
      expression: "routerTransition"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Menu Layout"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "layout-type",
      "options": _vm.layoutTypeOptions
    },
    model: {
      value: _vm.layoutType,
      callback: function callback($$v) {
        _vm.layoutType = $$v;
      },
      expression: "layoutType"
    }
  })], 1), _vm.layoutType === 'vertical' ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Menu Collapsed")]), _c('b-form-checkbox', {
    staticClass: "mr-0",
    attrs: {
      "name": "is-vertical-menu-collapsed",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.isVerticalMenuCollapsed,
      callback: function callback($$v) {
        _vm.isVerticalMenuCollapsed = $$v;
      },
      expression: "isVerticalMenuCollapsed"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-2"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Menu Hidden")]), _c('b-form-checkbox', {
    staticClass: "mr-0",
    attrs: {
      "name": "is-menu-visible",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.isNavMenuHidden,
      callback: function callback($$v) {
        _vm.isNavMenuHidden = $$v;
      },
      expression: "isNavMenuHidden"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.layoutType === 'vertical',
      expression: "layoutType === 'vertical'"
    }],
    attrs: {
      "label": "Navbar Color"
    }
  }, _vm._l(_vm.navbarColors, function (color, index) {
    return _c('div', {
      key: color,
      staticClass: "p-1 d-inline-block rounded mr-1 cursor-pointer",
      class: ["bg-".concat(color), {
        'border border-light': !index
      }, {
        'mark-active': _vm.navbarBackgroundColor === color
      }],
      on: {
        "click": function click($event) {
          _vm.navbarBackgroundColor = color;
        }
      }
    });
  }), 0), _c('b-form-group', {
    attrs: {
      "label": _vm.layoutType === 'vertical' ? 'Navbar Type' : 'Menu Type'
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "navbar-type",
      "options": _vm.navbarTypes
    },
    model: {
      value: _vm.navbarType,
      callback: function callback($$v) {
        _vm.navbarType = $$v;
      },
      expression: "navbarType"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Footer Type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "footer-type",
      "options": _vm.footerTypes
    },
    model: {
      value: _vm.footerType,
      callback: function callback($$v) {
        _vm.footerType = $$v;
      },
      expression: "footerType"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "text-uppercase mb-0"
  }, [_vm._v("PT Sarana Abadi Lestari")]), _c('small', [_vm._v("Customize & Preview in Real Time")])]);
}]

export { render, staticRenderFns }