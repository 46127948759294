export default [
  {
    header: 'DATA MASTER [DM] 🔸',
    acl: [
      {
        resource: 'all',
        action: 'manage',
      },
      {
        resource: 'agen all',
        action: 'manage',
      },
      {
        resource: 'agen',
        action: 'manage',
      },
      {
        resource: 'transportir',
        action: 'manage',
      },
    ],
  },
  {
    title: 'Land Vehicles',
    icon: 'TruckIcon',
    tag: 'KD',
    tagVariant: 'warning',
    route: 'data-master-land-vehicles',
    acl: [
      {
        resource: 'all',
        action: 'manage',
      },
      {
        resource: 'agen all',
        action: 'manage',
      },
      {
        resource: 'agen',
        action: 'manage',
      },
    ],
  },
  {
    title: 'Person V1',
    icon: 'UsersIcon',
    tag: 'OS',
    tagVariant: 'warning',
    route: 'data-master-person-v1',
    acl: [
      {
        resource: 'all',
        action: 'manage',
      },
      {
        resource: 'agen all',
        action: 'manage',
      },
      {
        resource: 'transportir',
        action: 'manage',
      },
    ],
  },
]
