export default [
  {
    header: 'DATA MASTER [DM] 🔹',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'TBBM'
      // },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    title: 'Main Boat',
    icon: 'AnchorIcon',
    tag: 'TBBM',
    tagVariant: 'primary',
    route: 'data-master-river-boat-tbbm',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'TBBM'
      // },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  // {
  //   title: 'Tug Boat',
  //   icon: 'AnchorIcon',
  //   tag: 'TBBM/HM',
  //   tagVariant: 'primary',
  //   route: 'data-master-river-tug-boat',
  //   acl: [
  //     {
  //       resource: 'all',
  //       action: 'manage'
  //     },
  //     {
  //       resource: '',
  //       action: 'SAL'
  //     }
  //   ]
  // },
  {
    title: 'Main Boat',
    icon: 'AnchorIcon',
    tag: 'HM',
    tagVariant: 'primary',
    route: 'data-master-river-boat-hulu_migas',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    header: 'DATA MASTER [DM] 🔹',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'TBBM'
      // },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    title: 'Tug Boat',
    icon: 'AnchorIcon',
    tag: 'J3A',
    tagVariant: 'primary',
    route: 'data-master-river-tug-boat-j3a',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  }
  // {
  //   title: 'Speed Boat',
  //   icon: 'AnchorIcon',
  //   tag: 'SP',
  //   tagVariant: 'primary',
  //   route: 'data-master-speed-boat',
  //   acl: [
  //     {
  //       resource: 'all',
  //       action: 'manage'
  //     },
  //     // {
  //     //   resource: '',
  //     //   action: 'Hulu Migas'
  //     // },
  //     {
  //       resource: '',
  //       action: 'SAL'
  //     }
  //   ]
  // }
]
