const myACL = [
  {
    resource: '',
    action: 'SAL',
  },
]

export default [
  {
    header: 'SAL Only',
    acl: myACL,
  },
  {
    title: 'Kegiatan',
    icon: 'CrosshairIcon',
    tag: 'KG',
    tagVariant: 'light-success',
    acl: myACL,
    children: [
      {
        title: 'TBBM',
        icon: 'GitPullRequestIcon',
        route: 'host-only-kegiatan-tbbm',
        tag: 'TB',
        tagVariant: 'primary',
        acl: myACL,
      },
      {
        title: 'Hulu Migas',
        icon: 'GitPullRequestIcon',
        route: 'host-only-kegiatan-hm',
        tag: 'HM',
        tagVariant: 'primary',
        acl: myACL,
      },
      {
        title: 'Speed Boat',
        icon: 'GitPullRequestIcon',
        route: 'host-only-kegiatan-sb',
        tag: 'SB',
        tagVariant: 'primary',
        acl: myACL,
      },
    ],
  },
  {
    title: 'Pemilik',
    icon: 'SlackIcon',
    tag: 'PM',
    tagVariant: 'light-success',
    acl: myACL,
    children: [
      {
        title: 'TBBM',
        icon: 'GitPullRequestIcon',
        route: 'host-only-pemilik-tbbm',
        tag: 'TB',
        tagVariant: 'primary',
        acl: myACL,
      },
      // {
      //   title: 'Hulu Migas',
      //   icon: 'GitPullRequestIcon',
      //   route: 'host-only-pemilik-hm',
      //   tag: 'HM',
      //   tagVariant: 'primary',
      //   acl: myACL,
      // },
    ],
  },
  {
    title: 'Jetty Position',
    icon: 'MoveIcon',
    route: 'host-only-jetty-position',
    tag: 'JM',
    tagVariant: 'primary',
    acl: myACL,
  },
]
