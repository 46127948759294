export default [
  // {
  //   title: 'Graph Analytic',
  //   icon: 'AnchorIcon',
  //   route: 'realtime-monitoring-river-route-graph',
  //   acl: [
  //     {
  //       resource: 'all',
  //       action: 'manage'
  //     },
  //     {
  //       resource: 'special',
  //       action: 'manage'
  //     },
  //     {
  //       resource: '',
  //       action: 'SAL'
  //     },
  //     {
  //       resource: '',
  //       action: 'TBBM'
  //     },
  //     {
  //       resource: '',
  //       action: 'Hulu Migas'
  //     },
  //     {
  //       resource: 'qrcode',
  //       action: 'manage'
  //     },
  //     {
  //       resource: 'jetty',
  //       action: 'manage'
  //     },
  //     {
  //       resource: '',
  //       action: 'approvement'
  //     }
  //   ]
  // },
  {
    title: 'Jetty View',
    icon: 'AnchorIcon',
    route: 'analytic-jalur-sungai-jetty-view',
    tag: '',
    tagVariant: 'primary',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: 'special',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: '',
        action: 'TBBM'
      },
      {
        resource: '',
        action: 'Hulu Migas'
      },
      {
        resource: 'qrcode',
        action: 'manage'
      },
      {
        resource: 'jetty',
        action: 'manage'
      },
      {
        resource: '',
        action: 'approvement'
      }
    ]
  },
  {
    title: 'Jetty View',
    icon: 'AnchorIcon',
    tag: '3 A',
    tagVariant: 'primary',
    route: 'analytic-jalur-sungai-jetty-view-j3a',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: 'jetty',
        action: 'manage'
      },
      {
        resource: '',
        action: 'TBBM JETTY 3A'
      }
    ]
  },
  {
    title: 'Boat Analytic',
    icon: 'AnchorIcon',
    route: 'river-route-analytic-count-tambat',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: 'qrcode',
        action: 'manage'
      },
      {
        resource: 'jetty',
        action: 'manage'
      },
      {
        resource: 'boat all',
        action: 'analytic'
      }
    ]
  },
  // {
  //   key: 'TB',
  //   title: 'Tug Boat',
  //   icon: 'AnchorIcon',
  //   route: 'dashboard-monitoring-rrv-tug-boat',
  //   tagVariant: 'primary',
  //   acl: [
  //     {
  //       resource: '',
  //       action: 'TBBM'
  //     },
  //     {
  //       resource: '',
  //       action: 'Hulu Migas'
  //     },
  //     {
  //       resource: '',
  //       action: 'SAL'
  //     },
  //     {
  //       resource: 'qrcode',
  //       action: 'manage'
  //     },
  //     {
  //       resource: 'jetty',
  //       action: 'manage'
  //     }
  //     // {
  //     //   resource: '',
  //     //   action: 'approvement'
  //     // }
  //   ]
  // },
  {
    header: 'FORM BY STATUS',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: 'special',
        action: 'manage'
      },
      {
        resource: '',
        action: 'TBBM'
      },
      {
        resource: '',
        action: 'Hulu Migas'
      },
      {
        resource: 'jetty',
        action: 'manage'
      },
      {
        resource: '',
        action: 'approvement'
      }
    ]
  },
  {
    tag: 'TA',
    tagVariant: 'light-success',
    title: 'Table Analytic',
    icon: 'AnchorIcon',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: 'special',
        action: 'manage'
      },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: '',
        action: 'TBBM'
      },
      {
        resource: '',
        action: 'Hulu Migas'
      },
      {
        resource: 'jetty',
        action: 'manage'
      },
      {
        resource: '',
        action: 'approvement'
      }
    ],
    children: [
      {
        title: 'Permohonan Kapal',
        icon: 'AnchorIcon',
        route: 'realtime-monitoring-river-route-table-permohonan-kapal-tbbm',
        tag: 'TBBM',
        tagVariant: 'success',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: 'jetty',
            action: 'manage'
          }
        ]
      },
      {
        title: 'Permohonan Kapal',
        icon: 'AnchorIcon',
        route:
          'realtime-monitoring-river-route-table-permohonan-kapal-hulu-migas',
        tag: 'HM',
        tagVariant: 'success',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: 'jetty',
            action: 'manage'
          }
        ]
      },
      {
        title: 'Kapal Sandar',
        icon: 'AnchorIcon',
        route: 'realtime-monitoring-river-route-table-sandar',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: 'jetty',
            action: 'manage'
          },
          {
            resource: '',
            action: 'approvement'
          }
        ]
      },
      {
        title: 'Rencana Sandar',
        icon: 'AnchorIcon',
        route: 'realtime-monitoring-river-route-table-sandar-rencana',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: 'jetty',
            action: 'manage'
          },
          {
            resource: '',
            action: 'approvement'
          }
        ]
      },
      {
        title: 'Kapal Berangkat',
        icon: 'AnchorIcon',
        route: 'realtime-monitoring-river-route-table-berangkat',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: 'jetty',
            action: 'manage'
          },
          {
            resource: '',
            action: 'approvement'
          }
        ]
      },
      {
        title: 'Kegiatan Dibatalkan',
        icon: 'AnchorIcon',
        route: 'realtime-monitoring-river-route-table-cancel',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: 'jetty',
            action: 'manage'
          },
          {
            resource: '',
            action: 'approvement'
          }
        ]
      }
    ]
  },
  {
    tag: 'SAL',
    title: 'Document Realisasi',
    icon: 'AnchorIcon',
    route: 'realtime-monitoring-river-route-table-sandar-rencana-sal',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      },
      {
        resource: 'jetty',
        action: 'manage'
      }
    ]
  }
]
