const myACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: '',
    action: 'SAL'
  },
  {
    resource: 'transportir',
    action: 'manage'
  },
  {
    resource: 'form1',
    action: 'manage'
  },
  {
    resource: 'form2',
    action: 'manage'
  }
  // {
  //   resource: '',
  //   action: 'TBBM'
  // },
  // {
  //   resource: '',
  //   action: 'Hulu Migas'
  // }
]

export default [
  {
    header: 'COMPANY OWN',
    acl: myACL
  },
  {
    title: 'Company',
    icon: 'CpuIcon',
    tag: 'CO',
    tagVariant: 'light-success',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: 'form1',
        action: 'manage'
      },
      {
        resource: 'form2',
        action: 'manage'
      },
      {
        resource: 'transportir',
        action: 'manage'
      }
    ],
    children: [
      {
        title: 'Agen',
        icon: 'PackageIcon',
        route: 'company-own-agen',
        tag: 'AG',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'GROUP_TEM',
            action: 'GROUP_TEM'
          }
        ]
      },
      {
        title: 'Transportir',
        icon: 'PackageIcon',
        route: 'company-own-transportir',
        tag: 'TR',
        tagVariant: 'warning',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          {
            resource: 'form1',
            action: 'manage'
          },
          {
            resource: 'form2',
            action: 'manage'
          },
          {
            resource: 'transportir',
            action: 'manage'
          }
        ]
      },
      {
        title: 'Shipping Agent',
        icon: 'PackageIcon',
        tag: 'SA',
        tagVariant: 'primary',
        route: 'data-master-shipping-agent',
        acl: [
          {
            resource: 'all',
            action: 'manage'
          },
          // {
          //   resource: '',
          //   action: 'TBBM'
          // },
          // {
          //   resource: '',
          //   action: 'Hulu Migas'
          // },
          // {
          //   resource: 'special',
          //   action: 'manage'
          // },
          {
            resource: '',
            action: 'SAL'
          }
        ]
      }
      // {
      //   title: 'Bongkar Muat',
      //   icon: 'PackageIcon',
      //   tag: 'BM',
      //   tagVariant: 'primary',
      //   route: 'data-master-company-bongkar-muat',
      //   acl: [
      //     {
      //       resource: 'all',
      //       action: 'manage',
      //     },
      //     {
      //       resource: '',
      //       action: 'TBBM',
      //     },
      //     {
      //       resource: 'special',
      //       action: 'manage',
      //     },
      //     {
      //       resource: '',
      //       action: 'SAL',
      //     },
      //   ],
      // },
    ]
  }
]
