const myACL = [
  {
    resource: 'user',
    action: 'manage',
  },
  {
    resource: 'all',
    action: 'manage',
  },
]
export default [
  {
    header: 'SuperAdmin Interface',
    acl: myACL,
  },
  {
    title: 'Account',
    icon: 'UserIcon',
    route: 'sa-users',
    acl: myACL,
  },
  {
    title: 'Group',
    icon: 'UsersIcon',
    route: 'sa-groups',
    acl: myACL,
  },
]
