const myACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'agen',
    action: 'manage'
  },
  {
    resource: 'agen all',
    action: 'manage'
  },
  {
    resource: '',
    action: 'SAL'
  },
  {
    resource: 'special',
    action: 'manage'
  },
  {
    resource: '',
    action: 'TBBM'
  },
  {
    resource: '',
    action: 'TBBM JETTY 3A'
  },
  {
    resource: '',
    action: 'Hulu Migas'
  },
  {
    resource: 'qrcode',
    action: 'manage'
  },
  {
    resource: 'jetty',
    action: 'manage'
  }
]
export default [
  {
    header: 'ANALYTIC',
    acl: myACL
  },
  {
    title: 'Graph Analytic',
    icon: 'TruckIcon',
    route: 'realtime-monitoring-land-route',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      {
        resource: 'agen',
        action: 'manage'
      },
      {
        resource: 'form1',
        action: 'manage'
      },
      {
        resource: 'form2',
        action: 'manage'
      },
      {
        resource: 'form3',
        action: 'manage'
      },
      {
        resource: 'special',
        action: 'manage'
      },
      {
        resource: 'qrcode',
        action: 'manage'
      }
    ]
  }
]
