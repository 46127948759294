var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-none d-md-block"
        }, [_c('b-badge', {
          staticClass: "mr-2",
          attrs: {
            "href": "#",
            "variant": "info"
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LinkIcon"
          }
        }), _c('span', [_vm._v(" " + _vm._s(_vm.userData.group_id === null ? ' ' : _vm.userData.group.name === 'SLB' ? 'Hulu Migas' : _vm.userData.group.name) + " ")])], 1), _c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "variant": "warning"
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SettingsIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.synonimRole(_vm.userData.role)) + " ")])], 1)], 1), _c('div', {
          staticClass: "d-sm-flex user-nav d-none"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0"
        }, [_vm._v(" " + _vm._s(_vm.userData.full_name) + " ")]), _vm._v(" " + _vm._s(_vm.userData.email) + " "), _c('span', {
          staticClass: "user-status"
        })]), _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "variant": "light-primary",
            "badge": "",
            "badge-variant": "success"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v(" Keluar "), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(" ( " + _vm._s(_vm.userData.email) + " ) ")])])], 1), _c('b-dropdown-item', {
    staticClass: "d-block d-sm-none",
    attrs: {
      "link-class": "d-flex align-items-center"
    }
  }, [_c('span', [_c('b-badge', {
    attrs: {
      "href": "#",
      "variant": "info"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "LinkIcon"
    }
  }), _c('span', [_vm._v("GROUP : " + _vm._s(_vm.userData.group_id === null ? ' ' : _vm.userData.group.name) + " ")])], 1)], 1)]), _c('b-dropdown-item', {
    staticClass: "d-block d-sm-none",
    attrs: {
      "link-class": "d-flex align-items-center"
    }
  }, [_c('span', [_c('b-badge', {
    attrs: {
      "href": "#",
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "SettingsIcon"
    }
  }), _c('span', [_vm._v("Hak Akses : " + _vm._s(_vm.userData.role) + " ")])], 1)], 1)]), _vm._l(_vm.userData.ability, function (item, index) {
    return _c('b-dropdown-item', {
      key: index,
      attrs: {
        "link-class": "d-flex align-items-center"
      }
    }, [_c('b-badge', {
      attrs: {
        "href": "#"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "DatabaseIcon"
      }
    }), _c('span', [_vm._v(_vm._s(item.action) + " " + _vm._s(item.subject))])], 1)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }