const myACLRiver = [
  {
    resource: 'all',
    action: 'manage'
  },
  // {
  //   resource: 'agen',
  //   action: 'manage'
  // },
  // {
  //   resource: 'agen all',
  //   action: 'manage'
  // },
  {
    resource: '',
    action: 'SAL'
  },
  {
    resource: 'special',
    action: 'manage'
  },
  {
    resource: '',
    action: 'TBBM'
  },
  {
    resource: '',
    action: 'TBBM JETTY 3A'
  },
  {
    resource: '',
    action: 'Hulu Migas'
  },
  {
    resource: '',
    action: 'approvement'
  }
  // {
  //   resource: 'qrcode',
  //   action: 'manage'
  // }
]
export default [
  {
    title: 'Jalur Sungai',
    icon: 'AnchorIcon',
    tag: 'M.JS',
    tagVariant: 'light-success',
    acl: myACLRiver,
    children: [
      {
        key: 'tbbm_j3a',
        title: 'JETTY', // agen, admin agen, sal
        icon: 'MapIcon',
        route: 'dashboard-monitoring-rrv-tbbm-j3a',
        tag: '3A',
        tagVariant: 'primary',
        acl: [
          {
            resource: '',
            action: 'TBBM JETTY 3A'
          },
          {
            resource: '',
            action: 'SAL'
          }
          // {
          //   resource: 'special',
          //   action: 'manage'
          // },
          // {
          //   resource: '',
          //   action: 'approvement'
          // }
          // {
          //   resource: 'jetty',
          //   action: 'manage'
          // }
        ]
      },
      {
        key: 'tbbm',
        title: 'TBBM', // agen, admin agen, sal
        icon: 'MapIcon',
        route: 'dashboard-monitoring-rrv-tbbm',
        tag: 'TB',
        tagVariant: 'primary',
        acl: [
          {
            resource: '',
            action: 'TBBM'
          },
          {
            resource: '',
            action: 'SAL'
          },
          {
            resource: 'special',
            action: 'manage'
          },
          {
            resource: '',
            action: 'approvement'
          }
          // {
          //   resource: 'jetty',
          //   action: 'manage'
          // }
        ]
      },
      {
        key: 'hm',
        title: 'Hulu Migas',
        icon: 'MapIcon',
        route: 'dashboard-monitoring-rrv-hulu-migas',
        tag: 'HM',
        tagVariant: 'primary',
        acl: [
          {
            resource: '',
            action: 'Hulu Migas'
          },
          {
            resource: '',
            action: 'SAL'
          }
          // {
          //   resource: 'jetty',
          //   action: 'manage'
          // }
        ]
      }
      // {
      //   key: 'tb',
      //   title: 'Tug Boat',
      //   icon: 'MapIcon',
      //   route: 'dashboard-monitoring-rrv-form-tug-boat',
      //   tag: 'TU',
      //   tagVariant: 'primary',
      //   acl: [
      //     {
      //       resource: '',
      //       action: 'TBBM'
      //     },
      //     {
      //       resource: '',
      //       action: 'Hulu Migas'
      //     },
      //     {
      //       resource: '',
      //       action: 'SAL'
      //     }
      //     // {
      //     //   resource: 'jetty',
      //     //   action: 'manage'
      //     // }
      //   ]
      // }
      // {
      //   key: 'sb',
      //   title: 'Speed Boat',
      //   icon: 'MapIcon',
      //   route: 'dashboard-monitoring-rrv-speed-boat',
      //   tag: 'SB',
      //   tagVariant: 'primary',
      //   acl: [
      //     // {
      //     //   resource: '',
      //     //   action: 'Facility (SLB)'
      //     // },
      //     {
      //       resource: '',
      //       action: 'SAL'
      //     },
      //     {
      //       resource: 'jetty',
      //       action: 'manage'
      //     }
      //     // {
      //     //   resource: 'qrcode',
      //     //   action: 'manage'
      //     // }
      //   ]
      // }
    ]
  }
]
