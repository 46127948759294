const myACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'Operasional',
    action: 'Form'
  },
  {
    resource: 'Jasa Barang',
    action: 'Form'
  }
]
const myACLA = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'Operasional',
    action: 'Form'
  }
]
const myACLB = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'Jasa Barang',
    action: 'Form'
  }
]
export default [
  {
    title: 'Kegiatan',
    icon: 'BriefcaseIcon',
    tag: 'KG',
    tagVariant: 'light-primary',
    acl: myACL,
    children: [
      {
        key: 'Operasional',
        title: 'OPR Dermaga',
        icon: 'PackageIcon',
        route: 'form-kegiatan-operasional',
        // tag: 'KO',
        tagVariant: 'warning',
        acl: myACLA
      },
      {
        key: 'Laporan',
        title: 'Jasa Barang',
        icon: 'PrinterIcon',
        route: 'form-kegiatan-operasional-laporan',
        // tag: 'KO',
        tagVariant: 'warning',
        acl: myACLB
      }
    ]
  }
]
