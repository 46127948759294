const reportACL = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'DATA',
    action: 'EXPORT'
  }
]
const reportACLRiver = [
  {
    resource: 'all',
    action: 'manage'
  },
  {
    resource: 'DATA',
    action: 'EXPORT'
  },
  {
    resource: '',
    action: 'TBBM JETTY 3A'
  },
  {
    resource: '',
    action: 'TBBM'
  },
  {
    resource: '',
    action: 'Hulu Migas'
  },
  {
    resource: '',
    action: 'SAL'
  },
  {
    resource: 'special',
    action: 'manage'
  },
  {
    resource: '',
    action: 'approvement'
  }
]
export default [
  {
    header: 'REPORTING',
    acl: reportACLRiver
  },
  {
    title: 'JALUR DARAT',
    icon: 'TrelloIcon',
    tag: 'JD',
    tagVariant: 'warning',
    route: 'data-reporting-land-route',
    acl: reportACL
  },
  {
    title: 'JALUR SUNGAI',
    icon: 'AnchorIcon',
    tag: 'JD',
    tagVariant: 'warning',
    route: '',
    acl: reportACLRiver,
    children: [
      {
        key: 'kegiatan_operasional',
        title: 'Kegiatan Operasional',
        icon: 'MapIcon',
        route: 'data-reporting-river-route',
        // tag: 'KO',
        tagVariant: 'primary',
        acl: reportACLRiver
      },
      {
        key: 'supervisi_dan_monitoring',
        title: 'Supervisi dan Monitoring',
        icon: 'MapIcon',
        route: 'data-reporting-river-route-supervisi',
        // tag: 'KO',
        tagVariant: 'primary',
        acl: reportACLRiver
      }
    ]
  }
  // {
  //   title: 'JALUR SUNGAI',
  //   icon: 'TrelloIcon',
  //   tag: 'JS',
  //   tagVariant: 'primary',
  //   route: 'data-reporting-river-route',
  //   acl: [
  //     {
  //       resource: 'all',
  //       action: 'manage'
  //     },
  //     {
  //       resource: 'agen all',
  //       action: 'manage'
  //     },
  //     {
  //       resource: 'agen',
  //       action: 'manage'
  //     },
  //     {
  //       resource: '',
  //       action: 'TBBM'
  //     },
  //     {
  //       resource: '',
  //       action: 'Hulu Migas'
  //     },
  //     {
  //       resource: 'special',
  //       action: 'manage'
  //     },
  //     {
  //       resource: '',
  //       action: 'SAL'
  //     }
  //   ]
  // }
]
