var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    staticStyle: {
      "z-index": "999 !important"
    },
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.countNotification || 0,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        }), _vm.isLoading ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm.isLoading ? _c('small', [_c('i', [_vm._v(" Loading notification... ")])]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v("Notifications")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.countNotification) + " New ")])], 1)]), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, _vm._l(_vm.notifications, function (notification, index) {
    return _c('b-link', {
      key: index
    }, [_c('b-media', {
      style: notification.is_read === true ? 'background-color: white !important' : 'background-color: whitesmoke !important',
      on: {
        "click": function click($event) {
          return _vm.onTapNotification(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "size": "32",
              "src": "",
              "variant": notification.type
            }
          }, [_c('feather-icon', {
            attrs: {
              "size": "25",
              "icon": ['TBBM', 'Hulu Migas', 'FORM_TUG_BOAT'].includes(notification.form_type) ? 'AnchorIcon' : 'TruckIcon'
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [notification.tag === 'land_route' ? _c('div', [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification.title) + _vm._s(notification.action) + " ")]), _c('code', [_vm._v(_vm._s(_vm.moment(notification.created_at).fromNow()))])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.subtitle) + " "), _c('b-badge', {
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(notification.form_type) + " ")]), _vm._v("   "), notification.group ? _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(notification.group) + " ")]) : _vm._e(), _vm._v("   "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(notification.desc2) + " ")]), _vm._v("   "), _c('b-badge', {
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.copyText(notification.desc1);
        }
      }
    }, [_vm._v(" " + _vm._s(notification.desc1) + " ")]), _vm._v("   "), notification.company ? _c('b-badge', {
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(" " + _vm._s(notification.company) + " ")]) : _vm._e()], 1)]) : _c('div', [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification.title) + " " + _vm._s(notification.action) + " ")]), _c('code', [_vm._v(_vm._s(_vm.moment(notification.desc3).format('DD/MM/YYYY h:m A')))])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.subtitle) + " "), _c('b-badge', {
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(notification.form_type === 'FORM_TUG_BOAT' ? 'TUG BOAT' : notification.form_type) + " ")]), notification.pemilik ? _c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(notification.pemilik) + " ")]) : _vm._e(), _vm._v("   "), _c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(notification.desc2) + " ")]), _vm._v("   "), _c('b-badge', {
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.copyText(notification.desc1);
        }
      }
    }, [_vm._v(" " + _vm._s(notification.desc1) + " ")]), _vm._v("   "), notification.company ? _c('b-badge', {
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(" " + _vm._s(notification.company) + " ")]) : _vm._e()], 1)])])], 1);
  }), 1), _c('li', {
    staticClass: "dropdown-menu-footer"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.readAllNotification();
      }
    }
  }, [_vm._v("Read all notifications")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }