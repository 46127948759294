<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-none d-md-block">
        <!-- Your content goes here -->
        <b-badge href="#" variant="info" class="mr-2">
          <feather-icon icon="LinkIcon" class="mr-25" />
          <span>
            {{
              userData.group_id === null
                ? ' '
                : userData.group.name === 'SLB'
                ? 'Hulu Migas'
                : userData.group.name
            }}
          </span>
        </b-badge>
        <b-badge href="#" variant="warning" class="mr-1">
          <feather-icon icon="SettingsIcon" class="mr-25" />
          <span>{{ synonimRole(userData.role) }} </span>
        </b-badge>
      </div>
      <div class="d-sm-flex user-nav d-none">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.full_name }}
        </p>
        {{ userData.email }}
        <span class="user-status"> </span>
      </div>
      <!-- ${userData.avatar} -->
      <!-- :src="`${BACKEND_URL}/avatar.png`" -->
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      />
    </template>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>
        Keluar
        <span class="d-block d-sm-none"> ( {{ userData.email }} ) </span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      class="d-block d-sm-none"
    >
      <span>
        <b-badge href="#" variant="info">
          <feather-icon icon="LinkIcon" class="mr-25" />
          <span
            >GROUP :
            {{ userData.group_id === null ? ' ' : userData.group.name }}
          </span>
        </b-badge>
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      class="d-block d-sm-none"
    >
      <span>
        <b-badge href="#" variant="warning">
          <feather-icon icon="SettingsIcon" class="mr-25" />
          <span>Hak Akses : {{ userData.role }} </span>
        </b-badge>
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      v-for="(item, index) in userData.ability"
      :key="index"
    >
      <!-- <sal-access > -->
      <b-badge href="#">
        <feather-icon icon="DatabaseIcon" class="mr-25" />
        <span>{{ item.action }} {{ item.subject }}</span>
      </b-badge>

      <!-- </sal-access> -->
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  // BDropdownDivider,
  BAvatar
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      avatarText
    }
  },
  methods: {
    synonimRole(value) {
      if (value === 'client') {
        return 'User Role'
      }
      return value
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      if ('VUE_APP_LANDING_PAGE_URL' in process.env) {
        // Redirect to landing page
        window.location.href = process.env.VUE_APP_LANDING_PAGE_URL
      } else {
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      }
    }
  }
}
</script>
