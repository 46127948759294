var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "clearfix mb-0"
  }, [_c('span', {
    staticClass: "float-md-left d-block d-md-inline-block mt-25"
  }, [_c('small', [_vm._v(" © 2022 - " + _vm._s(new Date().getFullYear()) + " "), _c('a', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "href": "https://github.com/yogithesymbian",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" Yogi Arif Widodo ")]), _vm._v(" 🎸 "), _c('a', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" PT Sarana Abadi Lestari ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }